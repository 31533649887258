import React from 'react';
import { ProjectMainLayout } from 'layouts/ProjectMainLayout';
import { LogofolioGrid } from 'components/LogofolioGrid';
import { graphql, useStaticQuery } from 'gatsby';

export const LogofolioView = () => {
    const logos = useStaticQuery(graphql`
        query LogofolioImages {
            logo1: file(name: { eq: "logo-1" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE)
                }
            }
            logo2: file(name: { eq: "logo-2" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE)
                }
            }
            logo3: file(name: { eq: "logo-3" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE)
                }
            }
            logo4: file(name: { eq: "logo-4" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE)
                }
            }
            logo5: file(name: { eq: "logo-5" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE)
                }
            }
            logo6: file(name: { eq: "logo-6" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE)
                }
            }
            logo7: file(name: { eq: "logo-7" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE)
                }
            }
            logo8: file(name: { eq: "logo-8" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE)
                }
            }
            logo9: file(name: { eq: "logo-9" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE)
                }
            }
        }
    `);
    return (
        <ProjectMainLayout projectName="logofolio">
            <LogofolioGrid logos={Object.values(logos)} />
        </ProjectMainLayout>
    );
};
