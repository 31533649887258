import React from 'react';
import styled from 'styled-components';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';

const SGrid = styled.div`
    display: grid;
    max-height: 100vh;
    grid-template-columns: repeat(3, minmax(200px, 1fr));
    grid-template-rows: repeat(3, minmax(200px, 1fr));
    grid-gap: 0.8rem;
    flex-shrink: 0;

    @media (max-width: 1024px) {
        max-height: unset;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        grid-template-rows: repeat(auto-fit, minmax(200px, 1fr));
    }

    @media (max-width: 420px) {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(auto-fit, minmax(200px, 1fr));
    }
`;

const SLogoWrapper = styled.div`
    background: ${({ theme, isWhite }) => (isWhite ? theme.colors.logo_bg_light : theme.colors.logo_bg_dark)};
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px), (orientation: portrait) {
        height: 200px;
    }

    @media (max-width: 420px) {
        margin: 0 1.6rem;
    }
`;

const SLogo = styled(GatsbyImage)`
    height: 100%;

    @media (max-width: 768px), (orientation: portrait) {
        & img {
            object-fit: contain !important;
        }
    }
`;

export const LogofolioGrid = ({ logos }) => {
    const renderLogos = () =>
        logos.map((logo, index) => (
            <SLogoWrapper isWhite={index < 3}>
                <SLogo image={getImage(logo)} alt="logo" lastRow={index > 5} />
            </SLogoWrapper>
        ));
    return <SGrid>{renderLogos()}</SGrid>;
};
